import oBlock from '@/objects/o-block/o-block.vue';
import oForm from '@/objects/o-form/o-form.vue';
import oButton from '@/objects/o-button/o-button.vue';
import oLabel from '@/objects/o-label/o-label.vue';
import oInput from '@/objects/o-input/o-input.vue';
import mSession from '@/modules/m-session/m-session.vue';

export default {
  name: 'ResetPassword',
  components: {
    'o-block': oBlock,
    'o-form': oForm,
    'o-button': oButton,
    'o-label': oLabel,
    'o-input': oInput,
    'm-session': mSession
  },
  data() {
    return {
      loading: false,
      email: null,
      formErrors: {
        email: false
      }
    };
  },
  methods: {
    resetPassword() {}
  }
};
