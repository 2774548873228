export default {
  name: 'oButton',
  props: {
    label: String,
    type: {
      type: String,
      default: 'button'
    },
    icon: String,
    iconPosition: String,
    onlyIconOnMobile: {
      type: Boolean,
      default: false
    }
  }
};
